<script lang="ts" setup>
import { ref, nextTick, onMounted } from 'vue'
import { Dialog, useDialogPluginComponent } from 'quasar'
import * as pdfjsLib from 'pdfjs-dist/build/pdf'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import BaseDialog from '@/components/base/BaseDialog.vue'
import ObjectSelectionForm from '@/components/common/database/ObjectSelectionForm.vue'

interface Props {
  surveyId: string
}
const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits([...useDialogPluginComponent.emits])

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()

const userApi: UserApi = useApi()

const formFields = ref([])
let pdf
let pdfMapping
let variables
const isLoading = ref(true)
const totalPages = ref(0)
const pageNumber = ref(1)
const canvasRef = ref(null)
const scale = ref(1)
const currentAnswers = ref([])

pdfjsLib.GlobalWorkerOptions.workerSrc = `https://cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjsLib.version}/build/pdf.worker.min.mjs`

async function onShow() {
  try {
    isLoading.value = true
    // Load the template, mapping, variables and answers
    const { content, pdfMapping: pdfMapping_, variables: variables_, answers } = await userApi.getTemplateForm(props.surveyId)
    pdfMapping = pdfMapping_
    variables = variables_.variables
    variables = convertKeysToCamelCase(variables)

    console.log({ content, pdfMapping, variables, answers })
    const pdfBlobUrl: string = URL.createObjectURL(new Blob([content], { type: 'application/pdf' }));
    pdf = await pdfjsLib.getDocument(pdfBlobUrl).promise
    totalPages.value = pdf.numPages
    currentAnswers.value = answers.results
    console.log("currentAnswers", currentAnswers)

    // Get the right starting scale
    pdf.getPage(1).then((page) => {
      let viewport = page.getViewport({ scale: 1.0 })
      if (viewport.width < viewport.height) {
        scale.value = 800 / viewport.width
      } else {
        scale.value = 1200 / viewport.height
      }
      console.log('scale', scale.value)
    })

    // Render the PDF first page using PDF.js
    renderPage()

    // Map the variables and the answers to the PDF fields, to show inputs with variableId and existing answers
    renderInputs()

    isLoading.value = false

  } catch (e) {
    console.error('Error fetching PDF:', e);
    isLoading.value = false
  }
}

function toCamelCase(str) {
  return str.replace(/([-_][a-z])/g, (group) =>
    group.toUpperCase().replace('-', '').replace('_', '')
  );
}

function convertKeysToCamelCase(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      const camelCaseKey = toCamelCase(key);
      result[camelCaseKey] = convertKeysToCamelCase(obj[key]);
      return result;
    }, {});
  }
  return obj;
}

async function renderPage() {
  pdf.getPage(pageNumber.value).then((page) => {
    console.log('rendering')
    const canvas = canvasRef.value;
    // const viewport = page.getViewport({ scale: scale.value });

    const viewport = page.getViewport({ scale: scale.value})
    // console.log('viewport', vp.width, vp.height)
    console.log('viewport', viewport.width, viewport.height)
    canvas.width = viewport.width;
    canvas.height = viewport.height;
    const renderContext = {
      canvasContext: canvas.getContext('2d'),
      viewport: viewport,
    };
    page.render(renderContext)
  })
}


async function renderInputs() {
  pdf.getFieldObjects().then(async (fields) => {
      console.log('fields', fields)
      let extractedFields = Object.keys(fields).flatMap((key) => {
        return fields[key].map((field) => {
          if (!field.rect || field.type === 'button' || (field.page + 1 != pageNumber.value)) return
          // Find the answer for the current field
          let mapping = pdfMapping.find(mapping => mapping.field_name === field.name)
          if(!mapping) {return}
          const [ variableId, optionId ] = mapping.value.split('_')
          let answerValue
          const matchingAnswer = currentAnswers.value.find(answer => answer.variable === parseInt(variableId))
          // console.log('matchingAnswer', matchingAnswer)
          if (optionId) {
            answerValue = matchingAnswer?.value.find(option => option.id === optionId).value
          } else {
            answerValue = matchingAnswer?.value.value
          }
          let variable = variables.find(variable => variable.id === parseInt(variableId))
          if (!variable) {return}
          let hasMapping = variable?.databaseObjectModelFieldsMapping &&
          variable?.databaseObjectModelFieldsMapping[0].databaseObjectModelName !== ''
          // console.log('mapping', mapping, variableId, optionId, answerValue, hasMapping, variable)

          return {
            name: field.name,
            page: field.page,
            // rect format: [x1, y1, x2, y2], bottom left and top right points
            top: canvasRef.value.height - field.rect[3] * scale.value,
            left: field.rect[0] * scale.value,
            width: (field.rect[2] - field.rect[0]) * scale.value,
            height: (field.rect[3] - field.rect[1]) * scale.value,
            type: field.type,
            value: answerValue,
            answerId: matchingAnswer?.id,
            variable: variable,
            optionId: optionId,
            hasMapping: hasMapping,
          }
        }).filter(Boolean)
      })
      await nextTick()

      formFields.value = extractedFields.sort((a, b) => {
        if (a.top + a.height === b.top + b.height) {
          return a.left - b.left
        }
        return a.top - b.top
      }).filter(field => field !== undefined)
      console.log('updated fields', formFields.value)
    })

}

async function updateField(field) {
  // Update the value in currentAnswers
  let answer = currentAnswers.value.find(answer => answer.id === field.answerId)
  console.log('update field', field.value, field, ', from', answer.value)
  if (field.type === 'checkbox') {
    answer.value = { value: !answer.value.value }
  } else {
    if (field.optionId) {
      answer.value.find(option => option.id === field.optionId).value = field.value
    } else {
      answer.value = { value: field.value }
    }
  }
  console.log('updated answer', currentAnswers.value)
}

function onPrevPage() {
  if (pageNumber.value <= 1) return;
  pageNumber.value -= 1;
  renderPage()
  renderInputs()
}

function onNextPage() {
  if (pageNumber.value >= totalPages.value) return;
  pageNumber.value += 1;
  renderPage()
  renderInputs()
}

function onZoomPlus() {
  scale.value += 0.1
  renderPage()
  renderInputs()
}

function onZoomMinus() {
  scale.value -= 0.1
  renderPage()
  renderInputs()
}

function showDatabaseObjectSelectionForm(variable) {
  console.log("showDatabaseObjectSelectionForm", variable)
  const dialog = Dialog.create({
    component: ObjectSelectionForm,
    componentProps: {
      question: variable,
      databaseForInsert: ref(undefined),
    },
  })
    .onOk((databaseObject) => {
      console.log("databaseobject selected", databaseObject.fields)
      // Update all the fields for the variable
      formFields.value.forEach((formField) => {
        if (formField.variable.id === variable.id) {
          variable.databaseObjectModelFieldsMapping[0].data.forEach((mapping) => {
            // For each mapping of the variable, check if the attribute name corresponds to the field option
            if (mapping.variableFieldName === variable.options.find(option => option.id === formField.optionId)?.label) {
              // Then, retrieve the field in the database object
              let databaseObjectValue = databaseObject.fields.find(field => field.label === mapping.databaseObjectModelFieldName)
              console.log("databaseObjectValue", databaseObjectValue)
              formField.value = databaseObjectValue.value
              updateField(formField)
            }
          })
          // field.value = data.fields.find(field => field.label === variable.name).value
        }
      })
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function onSave() {
  // Save the input values as new answers and emit OK
  await userApi.updateFormAnswers(props.surveyId, currentAnswers.value).then(() => {
    isLoading.value = true
    emit('ok')
  })
}
</script>

<template>
  <q-dialog ref="dialogRef" @show="onShow" full-height full-width>
    <BaseDialog title="Formulaire" :show-actions="false" @on-dialog-cancel="onDialogCancel()" @hide="onDialogHide()" style="background-color: #f8fafc;" :is-dialog-loading="isLoading">
      <template #body>
        <!-- Fixed title and control bar -->
        <!-- <div style="position: fixed; top: 0; width: 100%; background-color: white; z-index: 10;"> -->
          <!-- Controls Row -->
          <div class="row justify-center items-center q-gutter-sm" style="margin-top: -65px; margin-left: 90px; margin-right: 30px;">
            <q-btn icon="chevron_left" outline round color="primary" :disable="pageNumber <= 1" @click="onPrevPage"></q-btn>
            <span class="text-caption"><b>{{ pageNumber }} / {{ totalPages }}</b></span>
            <q-btn icon="chevron_right" outline round color="primary" :disable="pageNumber === totalPages" @click="onNextPage"></q-btn>

            <q-btn label="+" outline round color="primary" @click="onZoomPlus"> </q-btn>
            <q-btn label="-" outline round color="primary" @click="onZoomMinus"> </q-btn>
            <!-- Button to save -->
            <q-btn outline color="primary" @click="onSave" style="margin-left: 30px">Enregistrer</q-btn>
          </div>
        <!-- </div> -->

        <!-- Scrollable area for canvas and form fields -->
        <q-scroll-area v-if="!isLoading" style="margin-top: 20px; height: calc(100vh - 150px);">
          <div style="position: relative; width: max-content; margin: 0 auto;">
            <canvas ref="canvasRef"></canvas>

            <!-- Form fields -->
            <div v-if="formFields && formFields.length > 0">
              <div v-for="field in formFields" :key="field.name">
                <div
                  :style="{
                    position: 'absolute',
                    top: field.top + 'px',
                    left: field.left + 'px',
                    width: field.width + 'px',
                    height: field.height + 'px',
                    }">
                  <input :type="field.type.replace('string', 'text')" v-model="field.value"
                  :style="{ position: 'absolute',  width: field.width + 'px', height: field.height + 'px', backgroundColor: field.value ? '#79eb65' : 'yellow'}"
                  @input="updateField(field)" />
                </div>
                <q-icon name="download" v-if="field.hasMapping" clickable class="cursor-pointer"
                  @click="showDatabaseObjectSelectionForm(field.variable)"
                  :size="`${Math.min(parseInt(field.height), 30)}px`"
                  :style="{
                  position: 'absolute',
                  left: `${parseInt(field.left) + parseInt(field.width) - Math.min(parseInt(field.height), 30)}px`,
                  top: `${parseInt(field.top)}px`,
                  }"/>
              </div>
            </div>
          </div>
        </q-scroll-area>
      </template>
      <template #actions></template>
    </BaseDialog>
  </q-dialog>


</template>
