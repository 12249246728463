<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { ref, inject, watch, provide, onMounted } from 'vue'
import { useApi } from '@/store/useAppStore'
import UserApi from '@/services/api/core/UserApi'
import { useAuthenticatedUser } from '@/store/useAuthenticatedUser'
import { useStore } from '@/store/store'
import { ProjectKey } from '@/models/symbols'
import { Dialog, Notify, useQuasar } from 'quasar'
import FileSaver from 'file-saver'
import NewDirectoryDialog from './NewDirectoryDialog.vue'
import NewFileWithTemplateDialog from './NewFileWithTemplateDialog.vue'
import ImportFilesDialog from './ImportFilesDialog.vue'
import ImportFilesDialogGlobal from './ImportFilesDialogGlobal.vue'
import FileNode from './FileNode.vue'
import FileVersionNode from './FileVersionNode.vue'
import DirectoryNode from './DirectoryNode.vue'
import ImportFromLOPDialog from './ImportFromLOPDialog.vue'
import {
  refreshFiles,
  createLabels,
  orderChildren,
  expandToNode,
  getNode,
  refreshFlatTree,
  orderParent,
} from './DocumentsCommon'
import DeleteNodesDialog from './DeleteNodesDialog.vue'
import MoveDocumentsToUploadTaskDialog from './MoveDocumentsToUploadTaskDialog.vue'
import MoveDocumentsToProjectDialog from './MoveDocumentsToProjectDialog.vue'
// import AutomatedTreatmentDialog from './AutomatedTreatmentDialog.vue'
import BetweenNodeDropZone from './BetweenNodeDropZone.vue'
import { scroll } from 'quasar'
const { getScrollTarget, setVerticalScrollPosition } = scroll
import { isInViewport } from '@/utils/domUtils'
import { removeAccents } from '@/utils/stringUtils'
import NewFileFromModelDialog from './NewFileFromModelDialog.vue'
import { event } from 'vue-gtag'

const route = useRoute()
const router = useRouter()
const documentId = inject('documentId')

const $q = useQuasar()

const store = useStore()
const userApi: UserApi = useApi()
const authenticatedUser = useAuthenticatedUser()

const project = inject(ProjectKey)
const lastEvent = inject('lastEvent')

const treeRef = ref()
const expandedNodes = ref([0])
const selectedNode = inject('documentTreeSelectedNode')

const documentsLoading = ref(true)

const collapseDocumentTree = ref(true)

const ticked = ref([])
provide('ticked', ticked)
provide('treeRef', treeRef)

//provide("selectedNodeRef", selectedNodeRef);
provide('expandedNodes', expandedNodes)

const projectsRef = inject('projectsRef')

// function scrollToElement(target, el) {
//   // const target = getScrollTarget(el)
//   const offset = el.offsetTop
//   console.log("target", target, offset);
//   const duration = 1000
//   setVerticalScrollPosition(target, offset, duration)
//   // target.scrollTop = offset
// }

function scrollToElement(target, el) {
  // const target = getScrollTarget(el)
  let offset = el.offsetTop

  while (el != target) {
    el = el.parentNode
    if (el.classList.contains('relative-position')) {
      offset += el.offsetTop
    }

    console.log('offset', offset, el.offsetTop, el)
  }

  console.log('target', target, offset)
  const duration = 200
  setVerticalScrollPosition(target, offset, duration)
  // target.scrollTop = offset
}

const projectExecutionScrollAreaRef = inject('projectExecutionScrollAreaRef')

onMounted(() => {
  console.log('documents - onMounted')

  if (documentId.value) {
    console.log('document selected - scrolling to document')
    const scrollTargetParent = projectExecutionScrollAreaRef.value.$el
    const scrollTarget = scrollTargetParent.querySelector('.q-scrollarea__container')
    const nodeToSelect = getNode(documentTreeNodes.value, documentId.value)
    console.log('nodeToSelect', nodeToSelect)

    if (nodeToSelect == undefined) {
      console.log('selectedNode does not exist§. Removed? Updating URL')
      // onNodeSelected(null);
      selectedNode.value = null
      return
    }

    try {
      if (!isInViewport(nodeToSelect.anchorElement)) {
        scrollToElement(scrollTarget, nodeToSelect.anchorElement)
      } else {
        console.log('already in viewport')
      }
    } catch (e) {
      console.log('error scrolling to element', e)
      // may occur when in flat view and a directory node is passed in query parameter documentId
    }
  }
})

const documentTree = await userApi.getUserProjectDocumentTree(project?.value.id)

let documentsPermissions = null
const projectPermissions = await userApi.getObjectPermissions(project.value.id, "UserProject", authenticatedUser.id)
console.log('documents - projectPermissions', projectPermissions)
if (projectPermissions.permissions.includes("documents_view_deep_rights") || (authenticatedUser.id == '6a4823a8-d0f6-4bef-a09d-e0118c3c9159') || (authenticatedUser.id == '25b5e841-ccdd-4451-8941-3038a1097b29')) {
  console.log('documents - has documents_view_deep_rights - need to get document tree nodes rights')
  let documentTreeNodesIds = []

  getDocumentTreeNodesIdsRecursive(documentTree.children)
  function getDocumentTreeNodesIdsRecursive(nodes) {
    nodes.forEach((node) => {
      documentTreeNodesIds.push(node.id)
      if (node.children) {
        getDocumentTreeNodesIdsRecursive(node.children)
      }
    })
  }
  console.log("documentTreeNodesIds", documentTreeNodesIds)
  documentsPermissions = await userApi.getObjectsPermissions(documentTreeNodesIds, "DocumentTreeNode", authenticatedUser.id)
  console.log("documentsPermissions", documentsPermissions)
}


const documentTreeUpdateCounter = inject('documentTreeUpdateCounter')
watch(documentTreeUpdateCounter, async () => {
  documentTreeNodes.value = await refreshFiles(userApi, project)
  refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)
})

createLabels(documentTree.children)

function getDocumentTreePermissions(nodeId) {
  const permissions = documentsPermissions.permissions.filter((permission) => {
    return permission.objectId === nodeId
  })
  if (permissions.length > 0) {
    return permissions[0].permissions
  }
  return null
}

function applyPermissions(nodes) {
  const result = []
  nodes.forEach((node) => {
    const permissions = getDocumentTreePermissions(node.id)
    console.log("documents - permissions", permissions)

    if ((authenticatedUser.id == '6a4823a8-d0f6-4bef-a09d-e0118c3c9159') || (authenticatedUser.id == '25b5e841-ccdd-4451-8941-3038a1097b29')) {
      if ((node.name.toLowerCase().includes('confidentiel')) || (node.name.toLowerCase().includes('factur'))) {
        console.log("confidentiel ou facturation")
        return
      }
    } else {
      if (!permissions.includes('view_documenttreenode')) {
        return
      }
    }
    result.push(node)
  })
  return result
}

// If the user has folder-level permissions assigned
if (projectPermissions.permissions.includes("documents_view_deep_rights")) {
  console.log("documents - applyPermissions")
  documentTree.children = applyPermissions(documentTree.children)
}

if ((authenticatedUser.id == '6a4823a8-d0f6-4bef-a09d-e0118c3c9159') || (authenticatedUser.id == '25b5e841-ccdd-4451-8941-3038a1097b29')) {
  console.log("documents - applyPermissions")
  documentTree.children = applyPermissions(documentTree.children)
}

function applyEditPermissions(nodes) {
  nodes.forEach((node) => {
    const permissions = getDocumentTreePermissions(node.id)
    console.log("documents - edit permissions", permissions)
    if (permissions.includes('change_documenttreenode')) {
      node.readOnly = false
    } else {
      node.readOnly = true
    }
  })
}

if (projectPermissions.permissions.includes("documents_edit_deep_rights")) {
  console.log("documents - applyEditPermissions")
  applyEditPermissions(documentTree.children)

}

const documentTreeNodes = inject('documentTreeNodes')
console.log('documentTreeNodes', documentTreeNodes.value)
documentTreeNodes.value[0].children = documentTree.children
console.log('documentTreeNodes', documentTreeNodes.value)

const viewTypeOptions = [
  {
    label: 'Arborescence',
    value: 'tree',
    icon: 'img:/file-tree-outline-custom.svg', // "mdi-file-tree-outline" // o_segment
  },
  {
    label: 'A plat',
    value: 'flat',
    icon: 'mdi-format-list-bulleted', // headline o_view_list
  },
]

let view = viewTypeOptions[0] // 'tree' by default
const userDefaultView = localStorage.getItem(`${project?.value.id}_view`)
if (userDefaultView !== null && userDefaultView == 'flat') {
  view = viewTypeOptions[1]
}
const viewType = ref(view)

if (documentId.value) {
  console.log('documents - Have a document to select', documentId.value)
  selectedNode.value = documentId.value
  const node = getNode(documentTreeNodes.value, selectedNode.value)
  // If selected node is a dictionnary, switch to tree view
  if (node.type == 1) {
    viewType.value = viewTypeOptions[0]
  }
  expandToNode(documentTreeNodes, documentId.value, expandedNodes)
  scrollToNode(selectedNode.value)
}

provide('viewType', viewType)


function scrollToNode(nodeId) {
  console.log('Scroll to node', nodeId)
  const node = getNode(documentTreeNodes.value, nodeId)
  if (node === undefined) {
    return
  }

  const nodeElement = document.getElementById('node-' + nodeId)
  console.log('nodeElement', nodeElement)
  if (nodeElement === null) {
    return
  }

  nodeElement.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
    inline: 'center',
  })
}

watch(
  () => documentId.value,
  () => {
    console.log('documents - documentId changed', documentId.value)
    if (documentId.value === undefined) {
      selectedNode.value = null
    } else {
      selectedNode.value = documentId.value
    }
  },
)

function onToggleCollapse() {
  collapseDocumentTree.value = !collapseDocumentTree.value
  if (collapseDocumentTree.value) {
    expandedNodes.value = [0]
  } else {
    treeRef.value.expandAll()
  }
}

function getCurrentNode() {
  if (selectedNode.value === null || selectedNode.value === undefined) {
    return null
  }
  const node = getNode(documentTreeNodes.value, selectedNode.value)
  if (node === undefined) {
    return null
  }
  return node
}

function getCurrentDirectory() {
  console.log('selectedNode', selectedNode.value)

  const currentNode = getCurrentNode()
  console.log('currentNode', currentNode)
  if (currentNode === null) {
    return null
  }

  if (currentNode.type === 1) {
    return currentNode
  }

  return currentNode.parent
}

async function createDirectoryReal(directoryName) {
  let newOrder = 1
  if (documentTreeNodes.value.length !== 0) {
    newOrder = documentTreeNodes.value[documentTreeNodes.value.length - 1].order + 1
  }

  let currentDirectory = getCurrentDirectory()

  console.log('creating directory', directoryName, currentDirectory)

  let newDirectory = await userApi.createDocumentTreeDirectory(project?.value.id, {
    name: directoryName,
    order: newOrder,
    parentId: currentDirectory?.id,
  })

  console.log('new directory', newDirectory)
  if (currentDirectory == null) {
    currentDirectory = documentTreeNodes.value[0]
  }

  createLabels([newDirectory], currentDirectory)
  currentDirectory.children.push(newDirectory)
  orderChildren(currentDirectory)

  refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)

  expandToNode(documentTreeNodes, newDirectory.id, expandedNodes)
  selectedNode.value = newDirectory.id

}

async function createDirectory() {
  const dialog = Dialog.create({
    component: NewDirectoryDialog,
    componentProps: {},
  })
    .onOk((directoryName) => {
      createDirectoryReal(directoryName)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

/** Import dialog from import button */
function showUploadFilesDialog() {
  const dialog = Dialog.create({
    component: ImportFilesDialog,
    componentProps: {
      project: project,
      currentDirectory: getCurrentDirectory(),
      isEmptyGed: documentTreeNodes.value[0].children.length == 0,
    },
  })
    .onOk(async (filesToUpload) => {
      documentTreeNodes.value = await refreshFiles(userApi, project)
      refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)
      // window.history.go();
      // filesToUpload.forEach((fileToUpload) => {
      //   documentTreeNodes.value.push({
      //     id: newId,
      //     type: "2",
      //     label: fileToUpload.name,
      //   });
      //   newId = newId + 1;
      // });

      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

const expandPostSearch = ref([0])

function applyTreeFilter(node, filter, expand=true, firstPass=true) {
  console.log("applyTreeFilter");

  if (node.type === 0  || filter === '') {
    return true
  }

  const isMatch = matchSearch(node.name, filter)

  if (isMatch && firstPass) {
    expandPostSearch.value.push(node.id)
  }

  // In tree view, keep node if parent is kept
  if (viewType.value.value == 'tree' && !isMatch && node.parent) {
    return applyTreeFilter(node.parent, filter, expand=true, firstPass=false)
  }

  return isMatch
}

function matchSearch(nodeName, filter) {
  const nodeNameLowerCase = removeAccents(nodeName.toLowerCase())
  const filterLowerCase = removeAccents(filter.toLowerCase())
  const filterWords = filterLowerCase.split(' ')
  const isMatch = filterWords.every((filterWord) => {
    return removeAccents(nodeNameLowerCase).includes(filterWord)
  })
  return isMatch
}

async function createNewDocument(fileType, fileName) {

  // Send GA event
  event('create_document', {
    event_category: 'Document',
    event_label: 'New Document'
  })

  if (fileType === 'Word') {
    fileName = fileName + '.docx'
  } else if (fileType === 'Excel') {
    fileName = fileName + '.xlsx'
  } else if (fileType === 'PowerPoint') {
    fileName = fileName + '.pptx'
  }

  let currentDirectory = getCurrentDirectory()

  const newDocument = await userApi.createDocumentTreeFileWithTemplate(project?.value.id, {
    fileType: fileType,
    name: fileName,
    order: 1,
    parentId: currentDirectory?.id,
  })

  lastEvent.value = {
    type: 4,
    documentTreeNode: {
      name: fileName,
    },
  }

  console.log('new document', newDocument)
  if (currentDirectory == null) {
    currentDirectory = documentTreeNodes.value[0]
  }

  createLabels([newDocument], currentDirectory)
  currentDirectory.children.push(newDocument)
  orderChildren(currentDirectory)

  refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)

  expandToNode(documentTreeNodes, newDocument.id, expandedNodes)
  selectedNode.value = newDocument.id

}

function showNewDocumentModal(documentType: string) {
  const dialog = Dialog.create({
    component: NewFileWithTemplateDialog,
    componentProps: {
      fileType: documentType,
    },
  })
    .onOk((infos) => {
      createNewDocument(infos.fileType, infos.fileName)
      dialog.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function createNewDocumentsFromBasicModels(basicModels) {
  console.log('creating new documents', basicModels)
  // Send GA event
  event('create_document', {
    event_category: 'Document',
    event_label: 'New Document From Model'
  })
  let currentDirectory = getCurrentDirectory()
  await userApi.createDocumentTreeFilesWithBasicModels(project?.value.id, {
    parentId: currentDirectory?.id,
    basicModels: basicModels,
  })
}

function showNewDocumentFromModelModal() {
  const dialog = Dialog.create({
    component: NewFileFromModelDialog,
    componentProps: {
    },
  })
    .onOk(async (basicModels) => {
      dialog.hide()
      $q.loading.show({
        message: 'Veuillez patienter pendant la création des documents...',
        boxClass: 'bg-grey-2 text-grey-9',
        spinnerColor: 'primary',
        delay: 200, // ms
      })

      await createNewDocumentsFromBasicModels(basicModels)
      documentTreeNodes.value = await refreshFiles(userApi, project)
      refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)

      $q.loading.hide()
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function showImportFromLOPDialog() {
  const dialog = Dialog.create({
    component: ImportFromLOPDialog,
    componentProps: {
      project: project,
    },
  })
    .onOk(async (infos) => {
      console.log('Imported', infos)
      dialog.hide()
      project.value.lopMatterId = infos.matterId
      documentTreeNodes.value = await refreshFiles(userApi, project)
      refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)
    })
    .onCancel(() => {
      dialog.hide()
    })
}

watch(selectedNode, () => {
  console.log('watch selected node', selectedNode.value)
  onNodeSelected(selectedNode.value)
})

function onNodeSelected(selectedNodeId) {
  console.log('new node selected', selectedNodeId)

  const newRouteQuery = {}

  if (route.query.lateralTab != undefined) {
    newRouteQuery.lateralTab = route.query.lateralTab
  }

  if (selectedNodeId != null) {
    newRouteQuery.documentId = selectedNodeId
  }

  if (store.state.user.portal === 'CLIENT') {
    router.push({
      name: 'projects/project',
      params: {
        projectId: route.params.projectId.toString(),
      },
      query: newRouteQuery,
    })
  } else {
    router.push({
      name: 'clients/client/projects/project',
      params: {
        clientId: route.params.clientId.toString(),
        projectId: route.params.projectId.toString(),
      },
      query: newRouteQuery,
    })
  }
}

const webSocketBaseUrl = userApi.api.BASE_URL.replace('http', 'ws')
var desktopApplicationSocket
var webSocketTimeout
const webSocketReadyState = ref(WebSocket.CONNECTING)

function connectWebSocket() {
  console.log('Connecting web socket')
  desktopApplicationSocket = new WebSocket(webSocketBaseUrl + `ws/desktop_application/`)

  // https://stackoverflow.com/questions/22431751/websocket-how-to-automatically-reconnect-after-it-dies
  // https://raw.githubusercontent.com/joewalnes/reconnecting-websocket/master/reconnecting-websocket.js

  desktopApplicationSocket.onopen = function () {
    console.log('Socket is opened')
    clearTimeout(webSocketTimeout)
    webSocketReadyState.value = WebSocket.OPEN
  }

  desktopApplicationSocket.onclose = function (e) {
    console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason)
    clearTimeout(webSocketTimeout)
    webSocketTimeout = setTimeout(function () {
      connectWebSocket()
    }, 1000)
  }

  desktopApplicationSocket.onerror = function (event) {
    console.error('Socket encountered error: ', event, 'Closing socket')
    desktopApplicationSocket.close()
  }

  desktopApplicationSocket.onmessage = function (e) {
    const data = JSON.parse(e.data)
    console.log('desktopApplicationSocket.onmessage', data)

    if (data.type === 'document_tree_node_updated') {
      const node = getNode(documentTreeNodes.value, data.data.documentTreeNode.id)
      if (node !== undefined) {
        console.log('updating node', node, data.data.documentTreeNode.extractingData)
        node.editingUser = data.data.documentTreeNode.editingUser
        // node.extractingData = data.data.documentTreeNode.extractingData
        if (data.data.documentTreeNode.extractingData) {
          node.extractingData = { value: true }
        } else {
          node.extractingData = null
        }
        console.log('updated node', node)
      }
    }

    if (data.type === 'document_tree_node_version_added') {
      const node = getNode(documentTreeNodes.value, data.data.documentTreeNode.id)
      if (node !== undefined) {
        console.log('adding node version', node, data)

        const newFileVersionNode = {
          createdAt: data.data.documentTreeNode.version.createdAt,
          user: data.data.documentTreeNode.version.user,
        }
        node.file.versions.unshift(newFileVersionNode)

        const newVersionNode = {
          id: data.data.documentTreeNode.version.id,
          type: 3,
          name: node.name,
          label: 'label',
          version: data.data.documentTreeNode.version,
          icon: 'xx',
          children: [],
          noTick: true,
        }
        node.children.unshift(newVersionNode)

        lastEvent.value = {
          type: 6,
          documentTreeNode: {
            name: node.name,
          },
        }
      }
    }
  }
}

connectWebSocket()

window.addEventListener('online', function () {
  console.log('Connected')
})

window.addEventListener('offline', function () {
  console.log('Disconnected')
})

function deleteNodesInTree(nodeIds) {
  console.log('deleting nodes in tree')
  nodeIds.forEach((nodeId) => {
    try {
      const node = getNode(documentTreeNodes.value, nodeId)
      let parentNode = getNode(documentTreeNodes.value, node.parentId)
      if (!parentNode) {
        console.log('parent node not found', documentTreeNodes.value)
        parentNode = documentTreeNodes.value[0]
      }
      console.log('parent node', parentNode)
      parentNode.children = parentNode.children.filter((n) => n.id !== node.id)
      console.log('parent node', parentNode)
    } catch (e) {
      console.log('error deleting node', nodeId, e)
    }
  })
  ticked.value = []
}

function deleteNodes(nodeIds) {
  // console.log("deleting node", nodes);
  // const nodeIds = nodes.map((node) => node.id);
  console.log('deleting node', nodeIds)
  userApi.deleteDocumentTreeNodes(project?.value.id, nodeIds) // TODO: reactivate await after fix bug in back which is stuck in delete()

  deleteNodesInTree(nodeIds)
}

function batchRemove() {
  const dialog = Dialog.create({
    component: DeleteNodesDialog,
    componentProps: {
      // node: node,
    },
  })
    .onOk(() => {
      dialog.hide()
      deleteNodes(ticked.value)
      refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)
    })
    .onCancel(() => {
      dialog.hide()
    })
}

async function batchDownloadZip() {
  try {
    const doc = await userApi.downloadDocumentTreeFilesAsZip(project?.value.id, ticked.value)

    var reader = new FileReader()
    reader.readAsArrayBuffer(new Blob([doc]))
    reader.onload = function () {
      const newFile = new File([new Blob([reader.result])], 'file', {
        type: 'application/octet-stream',
      })

      FileSaver(newFile, 'documents.zip')
    }
    reader.onerror = function (error) {
      // setloadingSaveDoc("error");
      // setTimeout(() => {
      //   setloadingSaveDoc(false);
      // }, 1500);
    }
  } catch (e) {
    console.error(e)
    Notify.create({
      message: 'Erreur lors du téléchargement',
      type: 'negative',
    })
  }
}

function batchMoveToTask() {
  const dialog = Dialog.create({
    component: MoveDocumentsToUploadTaskDialog,
    componentProps: {
      project: project,
      action: 'move',
    },
  })
    .onOk(async (destinationTask) => {
      dialog.hide()

      $q.loading.show({
        message: 'Veuillez patienter pendant le déplacement des fichiers...',
        boxClass: 'bg-grey-2 text-grey-9',
        spinnerColor: 'primary',
        delay: 200, // ms
      })

      await userApi.moveDocumentTreeDocumentsToUploadTask(
        project?.value.id,
        ticked.value,
        destinationTask.value.value.id,
      )

      deleteNodesInTree(ticked.value)

      refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)

      $q.loading.hide()

      Notify.create({
        message: 'Documents déplacés',
        type: 'primary',
      })

    })
    .onCancel(() => {
      dialog.hide()
    })
}

function batchCopyToTask() {
  const dialog = Dialog.create({
    component: MoveDocumentsToUploadTaskDialog,
    componentProps: {
      project: project,
      action: 'copy',
    },
  })
    .onOk(async (destinationTask) => {
      dialog.hide()

      $q.loading.show({
        message: 'Veuillez patienter pendant la copie des fichiers...',
        boxClass: 'bg-grey-2 text-grey-9',
        spinnerColor: 'primary',
        delay: 200, // ms
      })

      await userApi.copyDocumentTreeDocumentsToUploadTask(
        project?.value.id,
        ticked.value,
        destinationTask.value.value.id,
      )

      $q.loading.hide()

      Notify.create({
        message: 'Documents copiés',
        type: 'primary',
      })

    })
    .onCancel(() => {
      dialog.hide()
    })
}

function batchMoveToProject() {
  const dialog = Dialog.create({
    component: MoveDocumentsToProjectDialog,
    componentProps: {
      project: project,
      action: 'move',
    },
  })
    .onOk(async (destinationProject) => {
      dialog.hide()

      console.log('destinationProject', destinationProject)

      $q.loading.show({
        message: 'Veuillez patienter pendant le déplacement des fichiers...',
        boxClass: 'bg-grey-2 text-grey-9',
        spinnerColor: 'primary',
        delay: 200, // ms
      })

      await userApi.moveDocumentTreeDocumentsToProject(project?.value.id, ticked.value, destinationProject.id)

      deleteNodesInTree(ticked.value)
      refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)

      $q.loading.hide()

      Notify.create({
        message: 'Documents déplacés',
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

function batchCopyToProject() {
  const dialog = Dialog.create({
    component: MoveDocumentsToProjectDialog,
    componentProps: {
      project: project,
      action: 'copy',
    },
  })
    .onOk(async (destinationProject) => {
      dialog.hide()

      await userApi.copyDocumentTreeDocumentsToProject(project?.value.id, ticked.value, destinationProject.id)

      Notify.create({
        message: 'Documents copiés',
        type: 'primary',
      })
    })
    .onCancel(() => {
      dialog.hide()
    })
}

/** Freezes the Documents while an AI treatment is in progress */
// function freezeDocuments() {
// }

/** Opens the AI treatment dialog */
// function automatedTreatment() {
//   const dialog = Dialog.create({
//     component: AutomatedTreatmentDialog,
//     componentProps: {
//       project: project,
//       ticked: ticked,
//       selectAllTreeNodes: selectAllTreeNodes.value,
//     },
//   })
//     .onOk(async () => {
//       dialog.hide()
//       // freezeDocuments();
//       // await refreshFiles(userApi, project, documentTreeNodes);
//       // await window.history.go();
//     })
//     .onCancel(() => {
//       dialog.hide()
//     })
// }

function tickChildren(nodeId) {
  const node = getNode(documentTreeNodes.value, nodeId)
  if (node.type === 1) {
    node.children.forEach((childNode) => {
      if (!ticked.value.includes(childNode.id)) {
        ticked.value.push(childNode.id)
      }
      tickChildren(childNode.id)
    })
  }
}

function untickChildren(nodeId) {
  const node = getNode(documentTreeNodes.value, nodeId)

  if (node == undefined) {
    return
  }

  if (node.type === 1) {
    node.children.forEach((childNode) => {
      if (ticked.value.includes(childNode.id)) {
        ticked.value = ticked.value.filter((n) => n !== childNode.id)
      }
      untickChildren(childNode.id)
    })
  }
}

watch(ticked, (newValue, oldValue) => {
  console.log('ticked', ticked.value, newValue, oldValue)
  newValue.forEach((nodeId) => {
    if (oldValue.includes(nodeId)) {
      console.log('node already ticked', nodeId)
    } else {
      console.log('node ticked', nodeId)
      tickChildren(nodeId)
    }
  })

  oldValue.forEach((nodeId) => {
    if (newValue.includes(nodeId)) {
      console.log('node still ticked', nodeId)
    } else {
      console.log('node unticked', nodeId)
      untickChildren(nodeId)
    }
  })
})

console.log('docmenttreenodes', documentTreeNodes.value)

const rootDropZone = ref(null)
// function onDragOverRootDropZone(event) {
//   console.log("onDragOverRootDropZone", event);
// }

function onDragEnterRootDropZone(event) {
  console.log('onDragEnterRootDropZone', event)
  rootDropZone.value.classList.add('between-node-drop-zone-hover')
}

function onDragLeaveRootDropZone(event) {
  console.log('onDragLeaveRootDropZone', event)
  rootDropZone.value.classList.remove('between-node-drop-zone-hover')
}

const selectAllTreeNodes = ref(false)

watch(selectAllTreeNodes, (newValue, oldValue) => {
  console.log('selectAllTreeNodes', newValue, oldValue)
  if (newValue) {
    ticked.value = documentTreeNodes.value[0].children.map((node) => node.id)
  } else {
    ticked.value = []
  }
})

const searchText = ref('')
const treeFilter = ref('')

function searchDocuments() {
  console.log('searchDocuments', searchText.value)
  expandedNodes.value = [0]
  expandPostSearch.value = [0]
  treeFilter.value = searchText.value
  // Wait 100 ms for filter to take effect before expanding
  setTimeout(() => {
    expandPostSearch.value.forEach(nodeId => {
      expandToNode(documentTreeNodes, nodeId, expandedNodes)
    });
  }, 100)
}

function resetSearch() {
  searchText.value = ''
  searchDocuments()
}

const uploadFileZone = ref(null)

const treeContainer = ref(null)
const dropZoneOverlay = ref(null)
let treeContainerDragEventCounter = 0
let dragMode = undefined

function onUploadFileDragEnter(event) {
  console.log('onUploadFileDragEnter', event, event.dataTransfer.types, treeContainerDragEventCounter)
  // uploadFileZone.value.classList.add("upload-drop-zone-hover");

  const internalDraggedElement = event.dataTransfer.types.includes('itemid')

  if (!internalDraggedElement) {
    console.log('External drag enter')
    dragMode = 'copy'
    treeContainer.value.classList.add('upload-drop-zone-hover')
    dropZoneOverlay.value.classList.remove('hide-drop-zone-overlay')
    dropZoneOverlay.value.classList.add('show-drop-zone-overlay')
    treeContainerDragEventCounter++
    event.preventDefault()
  } else if (event.dataTransfer.dropEffect == 'move') {
    console.log('Internal drag enter')
    dragMode = 'move'
  }
}

function onUploadFileDragLeave(event) {
  console.log('onUploadFileDragLeave', event, event.dataTransfer.dropEffect, treeContainerDragEventCounter)
  // uploadFileZone.value.classList.remove("upload-drop-zone-hover");
  // if (event.dataTransfer.dropEffect == 'none') {

  if (dragMode == 'copy') {
    console.log('External drag leave')
    treeContainerDragEventCounter--
    if (treeContainerDragEventCounter == 0) {
      console.log('real leave')
      treeContainer.value.classList.remove('upload-drop-zone-hover')
      dropZoneOverlay.value.classList.remove('show-drop-zone-overlay')
      dropZoneOverlay.value.classList.add('hide-drop-zone-overlay')
      dragMode = undefined
    }
    event.preventDefault()
  } else if (dragMode == 'move') {
    console.log('Internal drag leave')
    // dragMode = undefined;
  }
}

function onUploadFileDrop(event) {
  console.log('onUploadFileDrop', event, event.dataTransfer.items)
  treeContainerDragEventCounter = 0
  if (dragMode == 'copy') {
    event.preventDefault()
    dragMode = undefined
    treeContainer.value.classList.remove('upload-drop-zone-hover')
    dropZoneOverlay.value.classList.remove('show-drop-zone-overlay')
    dropZoneOverlay.value.classList.add('hide-drop-zone-overlay')

    // let files = []
    // for (let index = 0; index < event.dataTransfer.types.length; index++) {
    //   const type = event.dataTransfer.types[index];
    //   if (type === "Files") {
    //     files = event.dataTransfer.items[index];
    //   }
    // }

    const dialog = Dialog.create({
      component: ImportFilesDialogGlobal,
      componentProps: {
        project: project,
        currentDirectory: getCurrentDirectory(),
        dataTransferItems: event.dataTransfer.items,
        types: event.dataTransfer.types, // event.dataTransfer.files,
      },
    })
      .onOk(async (filesToUpload) => {
        documentTreeNodes.value = await refreshFiles(userApi, project)
        refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)
        dialog.hide()
      })
      .onCancel(() => {
        dialog.hide()
      })
  } else if (dragMode == 'move') {
    console.log('Internal drop')
  }
}

const dataTransferItems = ref([])
const elementsToUpload = ref([])

function readFiles(items) {
  console.log('dataTransferItems', items)
  for (let i = 0; i < items.length; i++) {
    let item = items[i].webkitGetAsEntry()
    console.log('dataTransferItem', i, item)
    if (item != null) {
      console.log('found files')
      dataTransferItems.value.push(item)
      if (item) {
        scanFiles(item, undefined)
      }
    } else {
      console.log('other type. bypassing')
    }
  }
}

function scanFiles(item, parent) {
  if (item.isDirectory) {
    elementsToUpload.value.push({
      type: 'directory',
      item: item,
      parent: parent,
    })
  } else {
    elementsToUpload.value.push({
      type: 'file',
      item: item,
      parent: parent,
    })
  }
}

onMounted(() => {
  console.log('documents - onMounted 2')
  treeContainer.value.addEventListener(
    'dragover',
    (event) => {
      event.preventDefault()
    },
    false,
  )
})

// view types

function onViewTypeChanged() {
  console.log('onViewTypeChanged', viewType.value)

  if (viewType.value.value == 'tree') {
    currentDocumentTreeNodes.value = documentTreeNodes.value
    expandToNode(documentTreeNodes, selectedNode.value, expandedNodes)
  } else if (viewType.value.value == 'flat') {
    currentDocumentTreeNodes.value = flatViewNodes.value
    selectedNode.value = null
  } else if (viewType.value.value == 'directory') {
    currentDocumentTreeNodes.value = directoryViewNodes.value
  }
  // Reset search
  searchText.value = ''
  searchDocuments()

  // Save last viewType in localStorage
  localStorage.setItem(`${project?.value.id}_view`, viewType.value.value)
}

const flatViewNodes = ref([
  {
    id: 0,
    type: 0,
    children: [],
  },
])
provide('flatViewNodes', flatViewNodes)

const currentDocumentTreeNodes = ref(documentTreeNodes.value)
provide('currentDocumentTreeNodes', currentDocumentTreeNodes)
refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)

const directoryViewNodes = ref([
  {
    id: 0,
    type: 0,
    children: [],
  },
])

watch(documentTreeNodes, () => {
  console.log('documentTreeNodes changed')
  // force refreshing currentDocumentTreeNodes
  onViewTypeChanged()
})

documentsLoading.value = false


async function duplicate() {
  console.log('duplicate')
  $q.loading.show({
    message: 'Veuillez patienter pendant la duplication...',
    boxClass: 'bg-grey-2 text-grey-9',
    spinnerColor: 'primary',
    delay: 200, // ms
  })

  await userApi.duplicateDocumentTreeNodes(project?.value.id, ticked.value)
  documentTreeNodes.value = await refreshFiles(userApi, project)
  refreshFlatTree(documentTreeNodes, flatViewNodes, viewType, currentDocumentTreeNodes)
  $q.loading.hide()
  Notify.create({
    message: `Duplication réalisée avec succès`,
    type: 'primary',
  })
}


//////////////
// Personal models
//////////////

const personalModels = ref([])
provide("personalModels", personalModels)

const personalModelsResponse = await userApi.getDocumentTreePersonalModels(false)
personalModels.value = personalModelsResponse
console.log("personalModels documents", personalModels)


const fileNb = ref(0)
provide("fileNb", fileNb)

function isAllowed(permission) {
  console.log("isAllowed", permission, authenticatedUser)
  // return true

  let result = false
  authenticatedUser.subscriptionModules?.forEach((module) => {
    if (module.environmentSubscriptionModule.environmentId == authenticatedUser.environment.id && module.environmentSubscriptionModule.subscriptionModule.permissionsJson.includes(permission)) {
      console.log("isAllowed", permission, "true")
      result = true
    }
  })

  console.log("isAllowed", permission, result)
  return result
}

</script>

<template>
  <div class="sticky-content">
    <div style="margin-left: 8px; margin-right: 8px; padding-top: 4px; padding-bottom: 4px"
      class="row no-wrap items-center">
      <span class="col-auto"
        style="font-size: 19px; font-weight:500; color: rgb(64, 104, 200); padding-right: 8px">Documents</span>
    </div>

    <!-- Add / search / actions  -->
    <div class="row no-wrap items-center" style="padding-bottom: 8px; padding-left: 8px; display: flex;">
      <span class="col-auto row no-wrap items-center" tyle="margin: 10px">
        <q-btn-dropdown dense flat rounded dropdown-icon="o_add">
          <q-list>
            <q-item clickable v-close-popup @click="createDirectory()" :disable="viewType.value == 'flat'">
              <q-item-section>
                <q-item-label>Nouveau dossier</q-item-label>
                <q-tooltip v-if="viewType.value == 'flat'">Non disponible dans la vue à plat</q-tooltip>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="showNewDocumentModal('Word')">
              <q-item-section>
                <q-item-label text-color="negative">Nouveau document Word</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="showNewDocumentModal('Excel')">
              <q-item-section>
                <q-item-label text-color="negative">Nouveau document Excel</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="showNewDocumentModal('PowerPoint')">
              <q-item-section>
                <q-item-label text-color="negative">Nouveau document PowerPoint</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="showNewDocumentFromModelModal()">
              <q-item-section>
                <q-item-label text-color="negative">Nouveau document depuis modèle</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>

        <q-btn size="size-sm" dense round flat label="" icon="o_cloud_upload" @click="showUploadFilesDialog()"></q-btn>

        <q-select rounded outlined dense options-dense v-model="viewType" :options="viewTypeOptions"
          class="view-type-select" @update:model-value="onViewTypeChanged">
          <template v-slot:option="scope">
            <!-- <div v-bind="scope.itemProps">
              <q-icon :name="scope.opt.icon" />
            </div> -->
            <q-item v-bind="scope.itemProps">
              <q-item-section avatar style="min-width: 0px">
                <q-icon :name="scope.opt.icon" size="20px" />
              </q-item-section>
              <q-item-section>
                <q-item-label>{{ scope.opt.label }}</q-item-label>
                <!-- <q-item-label caption>{{ scope.opt.description }}</q-item-label> -->
              </q-item-section>
            </q-item>
          </template>
          <template v-slot:selected-item="scope">
            <q-icon text-color="white" :name="scope.opt.icon" size="16px" />
          </template>
        </q-select>
      </span>

      <q-input dense outlined rounded v-model="searchText" @keydown.enter.prevent="searchDocuments"
        class="col-shrink search-input" style="min-width: 40px;">
        <template v-slot:prepend>
          <q-icon v-if="searchText === ''" name="search" size="" />
          <q-icon v-else name="clear" class="cursor-pointer" @click="resetSearch" />
        </template>
      </q-input>

      <q-space />

      <a v-if="store.state.user.portal !== 'CLIENT' && isAllowed('import_from_lop')" href="#"
        @click="showImportFromLOPDialog()" class="col-auto button-link import-from-lop-button-link"
        style="padding-left: 8px; padding-right: 8px">Importer
        depuis LOP</a>
      <span class="col-auto" style="padding-left: 8px; margin-right: 16px">
        <q-btn-dropdown dense rounded no-caps unelevated label="&nbsp;&nbsp;&nbsp;Actions" color="primary"
          :disable="ticked.length === 0" style="padding: 0.12em; padding-left: 0.2em; min-height: 1em">
          <q-list dense>
            <q-item clickable v-close-popup @click="batchDownloadZip()">
              <q-item-section>
                <q-item-label>Télécharger un zip</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="duplicate()">
              <q-item-section>
                <q-item-label>Dupliquer</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="batchMoveToTask()">
              <q-item-section>
                <q-item-label>Déplacer dans une tâche</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="batchCopyToTask()">
              <q-item-section>
                <q-item-label>Copier dans une tâche</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="batchMoveToProject()">
              <q-item-section>
                <q-item-label>Déplacer dans un autre dossier</q-item-label>
              </q-item-section>
            </q-item>
            <q-item clickable v-close-popup @click="batchCopyToProject()">
              <q-item-section>
                <q-item-label>Copier dans un autre dossier</q-item-label>
              </q-item-section>
            </q-item>
            <!-- <q-item clickable v-close-popup @click="automatedTreatment()">
              <q-item-section>
                <q-item-label>Traitements automatiques</q-item-label>
              </q-item-section>
            </q-item> -->
            <q-item clickable v-close-popup @click="batchRemove()">
              <q-item-section>
                <q-item-label>Supprimer</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </span>
    </div>

    <!-- Tree header -->
    <div class="row no-wrap justify-start content-start items-center tree-header"
      style="overflow: hidden; white-space: nowrap; margin-left: -3px">
      <div class="col-auto all-tree-checkbox" style="">
        <q-checkbox v-model="selectAllTreeNodes"></q-checkbox>
      </div>

      <div class="col-auto">
        <q-btn dense round flat :icon="collapseDocumentTree ? 'unfold_more' : 'unfold_less'" @click="onToggleCollapse"
          style="margin-left: -1px" v-if="viewType.value != 'flat'">
          <q-tooltip>{{
            collapseDocumentTree
              ? "Fermer toute l'arborescence de documents"
              : "Ouvrir toute l'arborescence de documents"
          }}</q-tooltip>
        </q-btn>
        <div v-else style="width: 32px; display: inline-block;"></div>
      </div>

      <div class="col-auto" style="overflow: hidden; padding-left: 31px">
        <div class="tree-column-header-name">Nom</div>
      </div>

      <!-- space filler -->
      <div class="col" style="overflow: hidden"></div>

      <div class="col-auto row no-wrap" style="overflow: hidden">
        <div class="tree-column-header-name" style="padding-left: 24px; padding-right: 90px">Auteur</div>
        &nbsp;&nbsp;
        <div class="tree-column-header-name" style="padding-right: 100px">Date</div>
      </div>
    </div>
    <!-- Tree header -->
  </div>
  <!-- sticky-content -->

  <div class="documents-content" ref="treeContainer" id="treeContainer" @dragenter="onUploadFileDragEnter"
    @dragleave="onUploadFileDragLeave" @drop="onUploadFileDrop" style="position: relative">
    <div ref="dropZoneOverlay" class="hide-drop-zone-overlay" style="padding-top: 12px">
      <div class="row vertical-middle" style="position: relative">
        <div class="col" style=""></div>
        <span class="col-auto vertical-middle" style="font-size: 16px; padding-top: 25px">Déposer des fichiers pour les
          importer</span>
        <div class="col" style=""></div>
      </div>
    </div>

    <div v-if="documentTreeNodes[0].children.length == 0" class="row" style="height: 100px; padding-top: 12px"
      ref="uploadFileZone">
      <div class="col" style=""></div>
      <q-icon name="o_cloud_upload" size="md" style="padding: 18px"></q-icon>
      <span class="col-auto vertical-middle" style="font-size: 16px; padding-top: 25px">
          Glisser des fichiers pour les importer</span>
      <div class="col" style=""></div>
    </div>

    <!-- <div v-if="viewType.value == 'flat'">Flat tree</div> -->

    <q-tree ref="treeRef" node-key="id" :nodes="currentDocumentTreeNodes" :filter="treeFilter"
      :filter-method="applyTreeFilter" style="margin-left: 8px; padding-bottom: 16px, margin-right: 2px;" dense
      v-model:expanded="expandedNodes" v-model:selected="selectedNode" selected-color="primary" tick-strategy="strict"
      v-model:ticked="ticked" class="document-tree">
      <template v-slot:default-header="prop">
        <div style="padding: 0px; overflow: hidden"
          class="row no-wrap justify-start items-start content-start full-width">
          <div v-if="prop.node.type === 0">&nbsp;</div>
          <DirectoryNode v-if="prop.node.type === 1" :element="prop.node" :treeRef="treeRef"></DirectoryNode>
          <FileNode v-if="prop.node.type === 2" :element="prop.node"></FileNode>
          <FileVersionNode v-if="prop.node.type === 3" :element="prop.node"></FileVersionNode>
        </div>
      </template>
      <template v-slot:default-body="prop">
        <BetweenNodeDropZone :element="prop.node"></BetweenNodeDropZone>
      </template>
    </q-tree>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/app.variables.scss';

.hide-drop-zone-overlay {
  display: none;
}

.show-drop-zone-overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: white;
}

.sticky-content {
  position: sticky;
  position: -webkit-sticky;
  top: 0px;
  z-index: 2;
  background-color: $page_background_color;
}

.documents-content {
  background-color: $page_content_background_color;
}

// View type
.view-type-select {
  margin-left: 6px;
  font-size: 24px;
}

:deep(.view-type-select .q-field__control) {
  min-height: 28px;
  height: 28px;
}

:deep(.view-type-select .q-field__control-container) {
  margin-top: -6px;
}

:deep(.view-type-select .q-field__append) {
  margin-top: -6px;
  padding-left: 0px;
  margin-right: -6px;
}

// Search input
.search-input {
  display: inline-flex;
  vertical-align: middle;
  padding-right: 0px;
  font-size: 14px;
}

:deep(.search-input .q-field__control) {
  height: 28px;
}

:deep(.search-input .q-field__marginal) {
  height: 28px;
  font-size: 16px;
}

:deep(.search-input .q-field__prepend) {
  padding-left: 2px;
}

:deep(.search-input .q-field__control) {
  padding-left: 2px;
  padding-right: 8px;
}

// Import from LOP
.import-from-lop-button-link {
  // padding-top: 10px;
  padding-left: 10px;
  // margin-top: 20px;
  // line-height: 4em;
}

// Actions menu

:deep(.q-btn-dropdown--simple * + .q-btn-dropdown__arrow) {
  margin-left: 0px;
}

// Tree header
.tree-column-header-name {
  font-size: 14px;
  font-weight: 500;
}

.tree-header {
  background-color: #f2f2f2;
  height: 28px;
}

.all-tree-checkbox {
  overflow: hidden;
  font-size: 32px;
  margin-top: -7px;
}

// Tree
:deep(.q-tree__node-header) {
  padding-left: 3px; // TODO: replace by a left move of the selection area
  padding-top: 0px;
  padding-bottom: 0px;
  margin: 0px;
}

// Hide tree root node
:deep(.q-tree > .q-tree__node > .q-tree__node-header) {
  height: 0px;
  width: 0px;
  overflow: hidden;
}

:deep(.tree-node) {
  height: 28px;
}

:deep(.tree-node-locked) {
  height: 40px;
  // background-color: red;
}

// Do not work: menu ... is going left
// Replaced to margin in whole tree
// :deep(.q-tree__node) {
//   padding-right: 2px;
// }

:deep(.q-tree__node--selected) {
  background-color: #dfdfdf;
  /* color: black; */
  font-weight: 500;
}

// Add space between arrow and checkbox
:deep(.q-tree__tickbox) {
  margin-left: 4px;
}

// :deep(.q-tree__arrow) {
//   margin-right: 6px;
// }

:deep(.q-checkbox__inner) {
  color: rgb(158, 158, 158);

  margin-right: 5px;
  font-size: 40px; // influe sur la taille de la checkbox
}

:deep(.q-checkbox__bg) {
  border: 1.3px solid currentColor;
}

// node-body
:deep(.q-tree__node-body) {
  padding: 0px;
}

:deep(.q-tree__node--parent > .q-tree__node-collapsible > .q-tree__node-body) {
  padding-bottom: 0px;
}

.document-tree {
  margin-right: 8px;
}

.upload-drop-zone-hover {
  border: 2px dashed #1a73e8;
}
</style>
